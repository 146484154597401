import JsFileDownloader from 'js-file-downloader'

export const linkDownload = (filename: string, url: string, type: string) => {
  // const link: any = document.createElement('a')
  // link.href = `${url}`
  // link.download = title
  // link.click()
  // const fileUrl = 'http://...';

  new JsFileDownloader({
    url,
    filename: `${filename}.${type}`
  }).catch(function (error: any) {
    console.error(error)
  })
}
